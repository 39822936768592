<template>
    <div class="box">
        <div class="title"></div>
        <p class="exc">
            风险排查功具备强大的数据分析能力。通过对海量数据的精准分析，能够快速准确地识别对方可能存在的各方面潜在风险因素。能够有效为您降低业务风险，
        </p>
        <div class="content">
            <div class="item">
                <p>曝光台检索</p>
                <img src="~/image/inspect/inspect_3.png" alt="" srcset="">
                <b>系统内曝光台进行检索，避免违约主播骗签</b>
            </div>
            <div class="item" style="margin: 0 20px;">
                <p>重复签约检索</p>
                <img src="~/image/inspect/inspect_2.png" alt="" srcset="">
                <b>系统内已签约信息检索，避免多次恶意签约</b>
            </div>
            <div class="item">
                <p>三要素报告</p>
                <img src="~/image/inspect/inspect_1.png" alt="" srcset="">
                <b>根据大数据，结合被查询人手机号，对其签约风险进行判断</b>
            </div>
        </div>
        <el-button type="primary" class="button" @click="toSearch">立即排查</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        toSearch() {
            this.$router.push("/inspect/inspectSearch")
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    background-image: url(~@/static/image/inspect/inspect_bg_1.png);
    background-size: 100% auto;
    background-color: #fff;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;

    .title {
        background-image: url(~@/static/image/inspect/inspect_title.png);
        width: 214px;
        height: 43px;
    }

    .exc {
        width: 781px;
        color: #262626;
        text-align: center;
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-top: 20px;
    }

    .content {
        margin-top: 30px;
        display: flex;

        .item {
            border-radius: 10px;
            border: 1.5px solid #fff;
            background: linear-gradient(180deg, rgba(231, 241, 255, 0.50) 0%, rgba(245, 245, 245, 0.50) 103.44%);

            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            backdrop-filter: blur(5px);
            width: 300px;
            height: 263px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                color: #262626;
                text-align: center;
                font-family: "Source Han Sans CN";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1px;
            }

            img {
                width: 90px;
                height: 80px;
                margin: 20;
            }

            b {
                width: 200px;
                color: #666;
                text-align: center;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1px;
            }
        }
    }

    .button {
        width: 400px;
        height: 60.971px;
        border-radius: 4px;
        background: #0062FF;
        color: #FFF;

        font-family: "Source Han Sans CN";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 50px;
    }
}
</style>